import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import GridWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/GridWrapper"
import SliderWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/SliderWrapper"

import shuffleArray from "@yumgmbh/gatsby-theme-yum-components/src/utils/shuffleArray"
import BlockHeader from "../../../../components/modules/BlockHeader"
import DefaultCard from "../../../../components/molecules/DefaultCard"

const CardsBlock = ({ block }) => {
  let view = block.view?.length > 0 ? block.view : "grid"
  let showSlides = parseInt(
    block.show_slides && block.show_slides !== "" ? block.show_slides : 4
  )

  let shuffle = block.shuffle?.length > 0 ? block.shuffle : false

  if (shuffle === true) {
    shuffleArray(block.card_items)
  }

  const cardVariants = {
    campaign16by9: {
      fitinImage: false,
      ratioImage: "16by9",
    },
    campaign1by1: {
      fitinImage: false,
      ratioImage: "1by1",
    },
    campaign4by3: {
      fitinImage: false,
      ratioImage: "4by3",
    },
    product4by3: {
      fitinImage: true,
      ratioImage: "4by3",
    },
  }

  if (view === "grid") {
    return (
      <BlockWrapper block={block}>
        <BlockHeader
          className="pb-2 md:pb-8"
          kicker={block.kicker}
          headline={block.headline}
          block={block.subheadline}
          text={block.text}
          links={block.link_items}
        />
        <GridWrapper showSlides={showSlides} className="-mb-8">
          {block.card_items?.length > 0 &&
            block.card_items.map((item, index) => (
              <div
                className={`block w-full mb-8 md:w-1/2 lg:w-1/${showSlides} px-grid`}
                key={index}
              >
                <DefaultCard
                  image={
                    item.image
                      ? item.image
                      : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                  }
                  image_fitin={cardVariants[block?.card_variant]?.fitinImage}
                  image_ratio={cardVariants[block?.card_variant]?.ratioImage}
                  title={item.headline}
                  text={item.text}
                  link={item.link}
                  link_text={item.link_text}
                  type={block.block_bg?.length > 0 ? "white" : "gray"}
                  className="cardsblock-ga4"
                />
              </div>
            ))}
        </GridWrapper>
      </BlockWrapper>
    )
  } else {
    return (
      <BlockWrapper block={block}>
        <BlockHeader
          className="pb-2 md:pb-8"
          kicker={block.kicker}
          headline={block.headline}
          block={block.subheadline}
          text={block.text}
          links={block.link_items}
        />
        <SliderWrapper
          uid={block._uid}
          showSlides={showSlides}
          hasHeadline={true}
        >
          {block.card_items?.length > 0 &&
            block.card_items.map((item, index) => (
              <div className={`w-full px-grid h-full`} key={index}>
                <DefaultCard
                  image={
                    item.image
                      ? item.image
                      : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                  }
                  image_fitin={cardVariants[block?.card_variant]?.fitinImage}
                  image_ratio={cardVariants[block?.card_variant]?.ratioImage}
                  kicker={item.kicker}
                  title={item.headline}
                  text={item.text}
                  link={item.link}
                  link_text={item.link_text}
                  type={block.block_bg?.length > 0 ? "white" : "gray"}
                  className="cardsblock-ga4"
                />
              </div>
            ))}
        </SliderWrapper>
      </BlockWrapper>
    )
  }
}

export default CardsBlock
