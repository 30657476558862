import React from "react"

import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import BlockHeader from "../modules/BlockHeader"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

const FeatureSectionBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockPadding="pt-block md:pb-16"
      className={block.block_bg?.length > 0 ? "bg-ro-gray-50" : "bg-ro-white"}
    >
      <BlockHeader
        className="pb-2 md:pb-8"
        kicker={block.kicker}
        headline={block.headline}
        block={block.subheadline}
        text={block.text}
        links={block.link_items}
      />
      <Link
        link={block.feature_link}
        className={`overlay-by-grid-16by9 mt-2 md:mt-0 group border active:outline active:outline-ro-red active:outline-2 active:-outline-offset-2 focus:outline focus:outline-ro-red focus:outline-2 focus:-outline-offset-2 ${
          block.block_bg?.length > 0
            ? "border-ro-gray-300"
            : "border-ro-gray-200"
        }`}
      >
        <Image
          image={block.feature_image}
          aspectRatio="16by9"
          className="hidden w-full md:block aspect-video"
        />
        <Image
          image={block.feature_image}
          aspectRatio="4by3"
          className="block md:hidden w-full aspect-[4/3]"
        />
        <div className="w-full md:h-full">
          <div className="-mx-grid md:h-full">
            <div className="w-full md:h-full md:flex md:items-end md:justify-end">
              <div className="w-full md:w-1/4 px-grid">
                <div
                  className={`flex flex-col justify-between p-4 transition-colors duration-300 border-t md:border md:border-r-0 md:border-b-0 ${
                    block.block_bg?.length > 0
                      ? "bg-ro-white group-hover:bg-ro-gray-75 border-ro-gray-300"
                      : "bg-ro-gray-50 group-hover:bg-ro-gray-75 border-ro-gray-200"
                  }`}
                >
                  <div>
                    {block.feature_headline?.length > 0 && (
                      <span className="text-base font-bold line-clamp-4 md:text-xl">
                        {block.feature_headline}
                      </span>
                    )}
                    {block.feature_text?.length > 0 && (
                      <span className="mt-4 text-ro-gray-600 line-clamp-5">
                        {block.feature_text}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-row mt-6 text-ro-red group-hover:text-ro-red-700">
                    {block.feature_link_text?.length > 0 && (
                      <span className="mr-2 line-clamp-1 underline-offset-4 group-hover:underline">
                        {block.feature_link_text}
                      </span>
                    )}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      size="1x"
                      className="self-center inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </BlockWrapper>
  )
}

export default FeatureSectionBlock
