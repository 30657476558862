import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import SliderWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/SliderWrapper"

import IconCard from "../molecules/IconCard"
import BlockHeader from "../modules/BlockHeader"

const QuicklinksBlock = ({ block }) => {
  let showSlides = parseInt(
    block.show_slides && block.show_slides !== "" ? block.show_slides : 4
  )

  return (
    <BlockWrapper
      block={block}
      className={block.block_bg?.length > 0 ? "bg-ro-gray-50" : "bg-ro-white"}
    >
      <BlockHeader
        className="pb-2 md:pb-8"
        kicker={block.kicker}
        headline={block.headline}
        block={block.subheadline}
        text={block.text}
        links={block.link_items}
      />
      <SliderWrapper uid={block._uid} showSlides={showSlides}>
        {block.card_items?.length > 0 &&
          block.card_items.map((item, index) => (
            <div className={`w-full h-full px-grid`} key={index}>
              <IconCard
                icon={item.icon}
                title={item.headline}
                text={item.text}
                link={item.link}
                link_text={item.link_text}
                type={block.block_bg?.length > 0 ? "white" : "gray"}
              />
            </div>
          ))}
      </SliderWrapper>
    </BlockWrapper>
  )
}

export default QuicklinksBlock
