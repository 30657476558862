import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import { Link } from "gatsby"
import FluidHeadline from "../atoms/FluidHeadline"

const HeroOverlayBlockMedia = ({ block }) => {
  if (block.video?.filename) {
    return (
      <video
        className="w-full h-auto aspect-video"
        autoPlay={true}
        muted={true}
        loop={true}
        src={block.video?.filename}
      />
    )
  } else {
    return (
      <>
        <Image
          image={block.image}
          title={block.title}
          alt={block.alt}
          className="hidden object-cover w-full h-auto aspect-[12/5] md:block"
          adaptToPixelRatio={true}
          //aspectRatio="12by5"
        />
        <Image
          image={block.image}
          title={block.title}
          alt={block.alt}
          className="block object-cover w-full h-auto aspect-[4/3] md:hidden"
          adaptToPixelRatio={true}
        />
      </>
    )
  }
}

const HeroOverlayBlock = ({ block, breadcrumbs }) => {
  return (
    <>
      {(block.image?.filename?.length > 0 ||
        block.video?.filename?.length > 0) && (
        <BlockWrapper
          block={block}
          blockPadding=""
          blockWidth="container"
          showHeadline="false"
        >
          <div className="relative -mx-4 overflow-hidden lg:-mx-10 bg-ro-white">
            <HeroOverlayBlockMedia block={block} />
            <div
              className={`bg-ro-gray-900 lg:bg-transparent lg:inset-0 lg:absolute ${
                block.no_gradient ? "" : "hero-gradient"
              }`}
            >
              <div className="h-full px-4 pt-10 pb-12 mx-auto md:pt-14 md:pb-16 lg:px-10">
                <div className="flex flex-col justify-between h-full lg:w-1/2">
                  <div>
                    {breadcrumbs?.length > 0 && (
                      <div className="block -ml-2 text-sm lg:mb-2">
                        {breadcrumbs?.map((breadcrumb, index) => (
                          <span key={index}>
                            {index > 0 && (
                              <span className="inline-block h-4 text-ro-gray-300">
                                /
                              </span>
                            )}
                            <Link
                              className={`px-2 hover:underline text-ro-gray-200`}
                              to={breadcrumb.link}
                            >
                              {breadcrumb.link_text}
                            </Link>
                          </span>
                        ))}
                      </div>
                    )}
                    <FluidHeadline
                      headline={block.headline}
                      className="pb-4 text-ro-white"
                      headlineLevel="04"
                      tagName="h1"
                    />
                  </div>
                  <div>
                    <Richtext
                      className="text-ro-white lg:w-3/4"
                      text={block.text}
                    />
                    {block.buttons && block.buttons.length > 0 && (
                      <div className="mt-6 -mb-4 md:mt-8">
                        {block.buttons.map((button, index) => (
                          <Button
                            key={index}
                            className={`headerbutton-ga4 flex w-full md:inline-block md:w-fit mb-4 ${
                              index + 1 < block.buttons.length ? "mr-4" : ""
                            }`}
                            btnType={index === 0 ? "primary" : "outlineWhite"}
                            link={button.link}
                            target={button.target}
                          >
                            {button.link_text}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default HeroOverlayBlock
