import React, { useState, useEffect } from "react"
// ---- SWIPER -----
// import Swiper core and required modules
import { Navigation, A11y, Controller, Scrollbar } from "swiper/modules"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/sharp-light-svg-icons"

const SliderWrapper = ({ children, uid, showSlides, className }) => {
  const [swiperLocked, setSwiperLocked] = useState(false)
  const [swiperInstance, setSwiperInstance] = useState(null)

  const uniqueId = uid || Math.floor(Math.random() * 10000)

  useEffect(() => {
    if (swiperInstance) {
      setSwiperLocked(swiperInstance.isLocked)
    }
  }, [swiperInstance])

  return (
    <div
      className={`ro-custom-swiper -mx-grid pt-2 md:pt-0 ${
        className ? className : ""
      }`}
    >
      <Swiper
        modules={[Navigation, A11y, Controller, Scrollbar]}
        spaceBetween={0}
        slidesPerView={1}
        onSwiper={setSwiperInstance}
        breakpoints={{
          672: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1056: {
            slidesPerView: showSlides,
            spaceBetween: 0,
          },
        }}
        scrollbar={{
          el: `#slider-wrapper-swiper-scrollbar-${uniqueId}`,
          draggable: true,
          dragSize: "auto",
        }}
        navigation={{
          disabledClass: "disabled",
          prevEl: `#slider-wrapper-swiper-prev-${uniqueId}`,
          nextEl: `#slider-wrapper-swiper-next-${uniqueId}`,
        }}
      >
        {children.map((item, i) => (
          <SwiperSlide key={`main-swiper-inPage-slide-${i}`}>
            <item.type {...item.props} />
          </SwiperSlide>
        ))}
      </Swiper>
      {!swiperLocked && (
        <div className="flex flex-col w-full gap-6 mt-6 px-grid md:mt-8 md:gap-8">
          <div id={`slider-wrapper-swiper-scrollbar-${uniqueId}`}></div>
          <div className="relative flex justify-end w-full gap-4">
            <button
              className="swiper-navigation-btn"
              id={`slider-wrapper-swiper-prev-${uniqueId}`}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="h-4" size="1x" />
            </button>
            <button
              className="swiper-navigation-btn"
              id={`slider-wrapper-swiper-next-${uniqueId}`}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="h-4"
                size="1x"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SliderWrapper
