import React from "react"

import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import BlockHeader from "../modules/BlockHeader"
import { Card, CardBody, CardHead } from "../molecules/Card"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

const FeatureCard = ({ item, className, cardType }) => {
  return (
    <Card
      className={`flex flex-row flex-wrap group ${
        className?.length > 0 ? className : ""
      }`}
      link={item.link}
      type={cardType}
    >
      <CardHead className="w-full md:w-1/2">
        <div className={item?.image_fitin ? "p-8 bg-ro-white" : ""}>
          <Image
            image={item.image}
            aspectRatio="4by3"
            className="block md:hidden w-full aspect-[4/3]"
            fitIn={item?.image_fitin}
          />
          <Image
            image={item.image}
            aspectRatio="1by1"
            className="hidden w-full md:block aspect-square"
            fitIn={item?.image_fitin}
          />
        </div>
      </CardHead>
      <CardBody className="flex flex-col justify-between w-full p-4 transition-colors duration-300 xl:p-8 md:w-1/2">
        <div className="mb-6">
          {item.headline?.length > 0 && (
            <span className="text-xl font-bold line-clamp-3">
              {item.headline}
            </span>
          )}
          {item.text?.length > 0 && (
            <span className="mt-2 text-ro-gray-900 line-clamp-4">
              {item.text}
            </span>
          )}
        </div>
        <div className="flex flex-row text-ro-red group-hover:text-ro-red-700 ">
          {item.link_text?.length > 0 && (
            <span className="mr-2 line-clamp-1 underline-offset-4 group-hover:underline">
              {item.link_text}
            </span>
          )}
          <FontAwesomeIcon
            icon={faArrowRight}
            size="1x"
            className="self-center inline-block"
          />
        </div>
      </CardBody>
    </Card>
  )
}

const FeatureCardsBlock = ({ block }) => {
  return (
    <BlockWrapper block={block}>
      <BlockHeader
        className="pb-2 md:pb-8"
        kicker={block.kicker}
        headline={block.headline}
        block={block.subheadline}
        text={block.text}
        links={block.link_items}
      />
      <div className="flex flex-row flex-wrap -mb-8 -mx-grid">
        {block.card_items?.length > 0 &&
          block.card_items.map((item, index) => (
            <div className="block w-full mb-8 lg:w-1/2 px-grid" key={index}>
              <FeatureCard
                item={item}
                cardType={block.block_bg?.length > 0 ? "white" : "gray"}
              />
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default FeatureCardsBlock
