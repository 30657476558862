import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import FluidHeadline from "../atoms/FluidHeadline"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

const BlockHeader = ({
  className,
  kicker,
  headline,
  subheadline,
  text,
  links,
}) => {
  if (
    kicker?.length > 0 ||
    headline?.length > 0 ||
    subheadline?.length > 0 ||
    text?.length > 0 ||
    links?.length > 0
  ) {
    return (
      <div className={className}>
        <div className="flex flex-row flex-wrap -mx-grid">
          <div className="w-full lg:w-1/2 px-grid">
            <FluidHeadline headline={headline} />
          </div>
          {(text?.length > 0 || links?.length > 0) && (
            <div className="w-full pb-2 lg:w-1/2 px-grid md:pb-8">
              <Richtext text={text} className="pt-2 mb-8 md:pt-0 last:mb-0" />
              {links?.length > 0 && (
                <div className="flex flex-col">
                  {links.map((item, index) => (
                    <Link
                      className={`flex ${
                        index < links.length - 1 ? "mb-4" : ""
                      } transition-colors duration-300 text-ro-red hover:text-ro-red-700 hover:underline active:bg-ro-black active:underline w-fit`}
                      link={item.link}
                      key={index}
                    >
                      {item.text}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        size="1x"
                        className="self-center inline-block ml-2"
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default BlockHeader
